import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const SurveyHelpManual = () => {
  const headingbanner = {
    title: `CSL`,
    content: `End-to-End Logistics Service Platform`,
  };

  const data = {
    images: ["fsl-1.webp", "fsl-2.webp"],
    firstAlt: "fsl",
    firstTitle: "fsl",
    secondAlt: "fsl",
    secondTitle: "fsl",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Saas"],
      },
      {
        title: "What we did",
        text: ["UX Research", "User Flows", "Information Architecture", 'UI Design', 'UI Development'],
      },
      {
        title: "Platform",
        text: ["Website"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The global freight forwarding company, Cargo Systems, excels in providing end-to-end logistics services, with a standout offering in Ocean and Air freight services for import and export operations.`,
          `However, despite their success, they lacked a digital solution to meet their growing business demands.`,
          `The absence of a centralized system for managing the vast amount of information required in their logistics support, scheduling, and management processes has led to significant challenges.`,
          'As a result, the efficiency of their freight forwarding operations is compromised, making it increasingly difficult to manage the intricate logistics involved in their business.',
          'To overcome these hurdles, the company needed a robust digital solution to streamline their operations and enhance efficiency.'
        ],
      },
      {
        title: "Solution",
        content: [
          `To resolve the issue of lack of centralized freight management, a comprehensive digital solution is proposed which is a web based platform, where users can search & generate the quotes for their shipments & can also manage all their shipments at one place.`,
          `We designed the website with a clean, minimalistic aesthetic to reflect the brand's sophistication and to emphasize the trustworthiness.`,
          `The platform also offers various payment modes to help all the users pay for their shipments hassle free. This platform offers a comprehensive suite of solutions that enable businesses to manage the complexities of modern supply chain management effectively.`,
        ],
      },
    ],
    image: ["fsl-problem.webp"],
  };

  const projectData = {
    title: "Landing Page",
    para: [
      `We created this page to showcase all the services & USPs that the CSL has to offer to their customers.`,
      `Also the Prominent Rate Engine helps users to search & generate their desired quote without wasting any time.`,
      `We also highlighted the key features of the website on the landing page so that users know what kind of feature rich experience they are going to have with the website.`,
      `To keeps users engaged on the website we also included the blogs section which helps users get more knowledge about shipping & the tools section for calculating the volume & taxes of their shipments.`,
    ],
    content: {
      image: ["fsl-projectone.webp"],
    },
  };

  const projectData2 = {
    title: `Mobile Responsive`,
    para: [
      `We wanted the website to be usable & accessible for all the users using desktop as well as mobile platforms.`,
      `Our responsive designs made it super convenient for the users to manage & track their shipments within the palm of their hands.`,
      `The website is fully responsive & gives as much good experience on mobile as desktop.`,
    ],
    content: {
      image: ["fsl-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Shipment Booking <br /> And payments`,
    para: [
      `With the user's perspective in mind, we've meticulously crafted our booking and payment page. Here, we allocate ample space to display comprehensive cargo shipping quotations, ensuring users can review all the details before booking.`,
      `Our payment page is equally user-friendly, accommodating various payment modes, including credit, pay later options, and digital methods like UPI and credit cards.`,
      `This thoughtful design enhances the user's experience, making the booking and payment process seamless and convenient.`,
    ],
    content: {
      image: [
        "fsl-projectthree.webp",
        "fsl-projectthree-2.webp",
        "fsl-projectthree-3.webp",
        "fsl-projectthree-4.webp",
        "fsl-projectthree-5.webp",
        "fsl-projectthree-6.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `In this journey we have demonstrated our commitment to delivering a user-centered design that benefits both business and our valued users.  `,
        `By meticulously considering the user's perspective, we have transformed the way CSL manages their shipping operations & created a comprehensive digital solution as per the needs of their growing business. `,
        `Simultaneously, for booking & payments experience, we prioritize transparency and usability, presenting multiple detailed freight shipping quotations and accommodating various payment modes, including credit, pay later options, and digital payment methods like UPI and credit cards, etc. `,
        `The solution aims to boost efficiency, reduce errors, and enhance decision-making, enabling Cargo Systems to meet growing demands and maintain a competitive edge in the dynamic cargo industry.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={41} />
      </div>
    </Layout>
  );
};

export default SurveyHelpManual;

export const Head = () => (
  <Seo title="Cargo Systems- Global Freight Forwarding | Octet  " description="A global cargo company, offering end-to-end logistics services. We assisted them with User Research, UI Design and UI Development services." />
)